import localforage from 'localforage';
import { useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { updateAppUserState } from 'redux/user/actions';
/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const useIdleTimeout = ({ onIdle, idleTime = 420 }: { onIdle: () => void; idleTime?: number }) => {
  const idleTimeout = 1000 * idleTime;
  const dispatch = useDispatch();
  const [isIdle, setIdle] = useState(false);
  const logoutUser = async () => {
    await localforage.clear();
    dispatch(
      updateAppUserState({
        currentUser: undefined,
        accessToken: undefined,
      }),
    );
  };
  const handleIdle = () => {
    setIdle(true);
  };

  const promptBeforeIdle = 90000;

  const idleTimer = useIdleTimer({
    timeout: idleTimeout - promptBeforeIdle,
    promptBeforeIdle,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    isIdle,
    setIdle,
    idleTimer,
    logoutUser,
  };
};
export default useIdleTimeout;
