import NotFoundComponent from 'components/NotFoundComponent';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { IAppPrivilegeState } from 'redux/privileges/reducer';

interface IProps {
  privilege: keyof IAppPrivilegeState;
  children: React.ReactNode;
  byPass?: boolean;
}
const PermissionRoute = ({ children, privilege, byPass }: IProps) => {
  const privileges = useAppSelector((state) => state.privileges);

  return byPass ? <>{children}</> : privileges[privilege] ? <>{children}</> : <NotFoundComponent />;
};

export default PermissionRoute;
