/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Benefits } from '../models/Benefits';
import type { BenefitsPartial } from '../models/BenefitsPartial';
import type { ChangeStages } from '../models/ChangeStages';
import type { ChangeStagesPartial } from '../models/ChangeStagesPartial';
import type { CoachProgramsPartial } from '../models/CoachProgramsPartial';
import type { CoachProgramsWithRelations } from '../models/CoachProgramsWithRelations';
import type { PlansBenefitPartial } from '../models/PlansBenefitPartial';
import type { ProgramPlans } from '../models/ProgramPlans';
import type { ProgramPlansPartial } from '../models/ProgramPlansPartial';
import type { Programs } from '../models/Programs';
import type { ProgramsPartial } from '../models/ProgramsPartial';
import type { ProgramsWithRelations } from '../models/ProgramsWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ProgramsControllerService {
  /**
   * @returns Benefits Benefits model instance
   * @throws ApiError
   */
  public static programsControllerAddBenefit({
    requestBody,
  }: {
    requestBody?: Benefits;
  }): CancelablePromise<Benefits> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/programs/addBenefit',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProgramPlans ProgramPlans model instance
   * @throws ApiError
   */
  public static programsControllerAddPlan({
    requestBody,
  }: {
    requestBody?: ProgramPlans;
  }): CancelablePromise<ProgramPlans> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/programs/addPlan',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Benefits Benefits model order bulk update
   * @throws ApiError
   */
  public static programsControllerAssignCoachesToProgram({
    requestBody,
  }: {
    requestBody?: Array<CoachProgramsPartial>;
  }): CancelablePromise<Benefits> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/programs/assignCoachesToProgram',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ChangeStages ChangeStages model instance
   * @throws ApiError
   */
  public static programsControllerUpdateStagesOfChange({
    programId,
    requestBody,
  }: {
    programId: string;
    requestBody?: ChangeStagesPartial;
  }): CancelablePromise<ChangeStages> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/programs/change-stages/{programId}',
      path: {
        programId: programId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static programsControllerUpdateCoachStatusInProgram({
    coachProgramId,
    requestBody,
  }: {
    coachProgramId: string;
    requestBody?: {
      status?: 'active' | 'inactive';
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/programs/coach/{coachProgramId}',
      path: {
        coachProgramId: coachProgramId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CoachProgramsWithRelations Array of CoachProgram model instances
   * @throws ApiError
   */
  public static programsControllerGetCoachProgramsCollection({
    filter,
    includeRelations,
  }: {
    filter?: any;
    includeRelations?: boolean;
  }): CancelablePromise<Array<CoachProgramsWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/programs/coach-programs',
      query: {
        filter: filter,
        includeRelations: includeRelations,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static programsControllerDeleteBenefit({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/programs/deleteBenefit/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static programsControllerDeletePlan({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/programs/deletePlan/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any Programs model instance
   * @throws ApiError
   */
  public static programsControllerGetProgramForUpdate({
    programId,
  }: {
    programId?: string;
  }): CancelablePromise<{
    plans?: Array<ProgramPlans>;
    benefits?: Array<Benefits>;
    program?: Programs;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/programs/getProgramForUpdate',
      query: {
        programId: programId,
      },
    });
  }

  /**
   * @returns Benefits Benefits model order bulk update
   * @throws ApiError
   */
  public static programsControllerRemoveCoachFromProgram({
    requestBody,
  }: {
    requestBody?: {
      programId?: string;
      coachId?: string;
    };
  }): CancelablePromise<Benefits> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/programs/removeCoachFromProgram',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Update successfull
   * @throws ApiError
   */
  public static programsControllerUpdateBenefit({
    requestBody,
  }: {
    requestBody?: Benefits;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/programs/updateBenefit',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns Benefits Benefits model order bulk update
   * @throws ApiError
   */
  public static programsControllerUpdateBenefitsBulk({
    requestBody,
  }: {
    requestBody?: Array<BenefitsPartial>;
  }): CancelablePromise<Benefits> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/programs/updateBenefitsBulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static programsControllerUpdatePlanById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ProgramPlansPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/programs/updatePlan/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static programsControllerUpdatePlansBenefitValue({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PlansBenefitPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/programs/updatePlansBenefitValue/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProgramPlans ProgramPlans model order bulk update
   * @throws ApiError
   */
  public static programsControllerUpdatePlansBulk({
    requestBody,
  }: {
    requestBody?: Array<ProgramPlansPartial>;
  }): CancelablePromise<ProgramPlans> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/programs/updatePlansBulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CoachProgramsWithRelations Array of CoachProgram model instances
   * @throws ApiError
   */
  public static programsControllerGetCoaches({
    id,
  }: {
    id: string;
  }): CancelablePromise<Array<CoachProgramsWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/programs/{id}/coaches',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static programsControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ProgramsPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/programs/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProgramsWithRelations Programs model instance
   * @throws ApiError
   */
  public static programsControllerGetProgramById({
    id,
  }: {
    id: string;
  }): CancelablePromise<ProgramsWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/programs/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns any Created a new Program
   * @throws ApiError
   */
  public static programsControllerCreateNewProgram({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/programs',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ProgramsWithRelations Array of Programs model instances
   * @throws ApiError
   */
  public static programsControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<ProgramsWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/programs',
      query: {
        filter: filter,
      },
    });
  }
}
