import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from 'App';
import ErrorBoundaryComponent from 'components/ErrorBoundary/ErrorBoundaryComponent';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-tooltip/dist/react-tooltip.css';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'redux/store';
import reportWebVitals from './reportWebVitals';
import ChatSocketProvider from 'utils/chatSocketProvider/ChatSocketProvider';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <>
    <Provider store={store}>
      <ErrorBoundaryComponent>
        <QueryClientProvider client={queryClient}>
          <PersistGate persistor={persistor}>
            <ChatSocketProvider>
              <App />
            </ChatSocketProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </PersistGate>
        </QueryClientProvider>
      </ErrorBoundaryComponent>
    </Provider>
  </>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
