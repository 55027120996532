/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewReportpost } from '../models/NewReportpost';
import type { ReportPost } from '../models/ReportPost';
import type { ReportPostPartial } from '../models/ReportPostPartial';
import type { ReportPostWithRelations } from '../models/ReportPostWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportPostControllerService {
  /**
   * @returns any Reportpost model count
   * @throws ApiError
   */
  public static reportPostControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report-posts/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static reportPostControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ReportPost;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/report-posts/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static reportPostControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ReportPostPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/report-posts/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ReportPostWithRelations Reportpost model instance
   * @throws ApiError
   */
  public static reportPostControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ReportPostWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report-posts/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static reportPostControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/report-posts/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ReportPost Reportpost model instance
   * @throws ApiError
   */
  public static reportPostControllerCreate({
    requestBody,
  }: {
    requestBody?: NewReportpost;
  }): CancelablePromise<ReportPost> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report-posts',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Reportpost PATCH success count
   * @throws ApiError
   */
  public static reportPostControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: ReportPostPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/report-posts',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ReportPostWithRelations Array of Reportpost model instances
   * @throws ApiError
   */
  public static reportPostControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<ReportPostWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report-posts',
      query: {
        filter: filter,
      },
    });
  }
}
