/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Publication } from '../models/Publication';
import type { PublicationWithRelations } from '../models/PublicationWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicationControllerService {
  /**
   * @returns any Publication model count
   * @throws ApiError
   */
  public static publicationControllerStatistics(): CancelablePromise<{
    publicationCount?: number;
    publishedCount?: number;
    unpublishedCount?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/publications/statistics',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static publicationControllerUpdateById({
    id,
    formData,
  }: {
    id: string;
    formData?: {
      title?: string;
      description?: string;
      externalLink?: string;
      citation?: string;
      status?: string;
      journalName?: string;
      publicationDate?: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/publications/{id}',
      path: {
        id: id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns PublicationWithRelations Publication model instance
   * @throws ApiError
   */
  public static publicationControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<PublicationWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/publications/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static publicationControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/publications/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Publication Publication model instance
   * @throws ApiError
   */
  public static publicationControllerCreate({
    formData,
  }: {
    formData?: {
      title?: string;
      description?: string;
      externalLink?: string;
      citation?: string;
      journalName?: string;
      publicationDate?: string;
    };
  }): CancelablePromise<Publication> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/publications',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Array of Publication model instances
   * @throws ApiError
   */
  public static publicationControllerFind({
    search,
    status,
    filter,
    page,
  }: {
    search?: string;
    status?: string;
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<PublicationWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/publications',
      query: {
        search: search,
        status: status,
        filter: filter,
        page: page,
      },
    });
  }
}
