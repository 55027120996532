import notFoundImage from 'assets/images/404_image.png';
import logo from 'assets/images/mdoc_logo.png';
import React from 'react';
class ErrorBoundaryComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="w-full h-screen bg-offwhite">
          <div className="p-4 absolute">
            <img src={logo} />
          </div>
          <div className="h-full flex-1 flex flex-col items-center justify-center px-8">
            <div>
              <img src={notFoundImage} />
            </div>
            <div className="w-[90%] md:w-[60%] lg:w-[30%]">
              <h1 className="text-center text-red-500 font-bold">Ooops!</h1>
              <p className="mt-4 text-center text-[14px]">
                Sorry, an unexpected error occurred. We have logged this error and our engineers
                will investigate and fix it as soon as possible.
              </p>
              <p className="my-4 text-center">Go Back to </p>

              <div className="w-full flex justify-center">
                <button
                  onClick={() => {
                    window.location.href = '/';
                  }}
                  width="50%"
                >
                  Dashboard
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundaryComponent;
