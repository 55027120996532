/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AppRelease } from '../models/AppRelease';
import type { AppReleasePartial } from '../models/AppReleasePartial';
import type { AppReleaseWithRelations } from '../models/AppReleaseWithRelations';
import type { NewAppRelease } from '../models/NewAppRelease';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppReleaseControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static appReleaseControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: AppReleasePartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/app-release/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static appReleaseControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/app-release/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns AppRelease New App model instance
   * @throws ApiError
   */
  public static appReleaseControllerCreate({
    requestBody,
  }: {
    requestBody?: NewAppRelease;
  }): CancelablePromise<AppRelease> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/app-release',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AppRelease Latest App Release model instances
   * @throws ApiError
   */
  public static appReleaseControllerFind(): CancelablePromise<AppRelease> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/app-release',
    });
  }

  /**
   * @returns any Array of AppRelease model instance
   * @throws ApiError
   */
  public static appReleaseControllerGetAppReleases({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<AppReleaseWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/fetch-app-releases',
      query: {
        filter: filter,
        page: page,
      },
    });
  }

  /**
   * @returns any App IP Address
   * @throws ApiError
   */
  public static appReleaseControllerFindIpAddress({
    token,
  }: {
    token?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/find-ip-address',
      query: {
        token: token,
      },
    });
  }
}
