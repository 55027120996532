import IdleTimerModal from 'components/IdleTimerModal/idleTimerModal';
import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { Loadable } from 'utils/ComponentLoader';
import useIdleTimer from 'utils/IdleTimer/useIdleTimer';
import useDisclosure from 'utils/useDisclosure/useDisclosure';

const DashboardComponent = Loadable(() => import('pages/DashboardComponent'), 'dashboard');

const PrivateDashboard = () => {
  const accessToken = useAppSelector((state) => state.user.accessToken);
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);

  const location = useLocation();

  const auth = !!(isLoggedIn && accessToken);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = useAppSelector((state) => state.user.currentUser);

  const { isIdle, setIdle, idleTimer, logoutUser } = useIdleTimer({
    onIdle: onOpen,
  });
  useEffect(() => {
    if (user === undefined) {
      setIdle(false); // Reset idle state if user is defined
    }
  }, [user, setIdle]);

  return auth ? (
    <>
      {' '}
      <DashboardComponent />{' '}
      {user ? (
        <IdleTimerModal
          isIdle={isIdle}
          idleTimer={idleTimer}
          isOpen={isOpen}
          logout={logoutUser}
          onClose={() => {
            setIdle(false);
            onClose();
          }}
        />
      ) : null}
    </>
  ) : (
    <Navigate to="/" replace state={{ from: location }} />
  );
};

export default PrivateDashboard;
