/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewReportcomment } from '../models/NewReportcomment';
import type { ReportComment } from '../models/ReportComment';
import type { ReportCommentPartial } from '../models/ReportCommentPartial';
import type { ReportCommentWithRelations } from '../models/ReportCommentWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportCommentControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static reportCommentControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ReportComment;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/report-comment/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static reportCommentControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: ReportCommentPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/report-comment/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns ReportCommentWithRelations Reportcomment model instance
   * @throws ApiError
   */
  public static reportCommentControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<ReportCommentWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report-comment/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static reportCommentControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/report-comment/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns ReportComment Reportcomment model instance
   * @throws ApiError
   */
  public static reportCommentControllerCreate({
    requestBody,
  }: {
    requestBody?: NewReportcomment;
  }): CancelablePromise<ReportComment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/report-comment',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Reportcomment model count
   * @throws ApiError
   */
  public static reportCommentControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report-comments/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns ReportCommentWithRelations Array of Reportcomment model instances
   * @throws ApiError
   */
  public static reportCommentControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<ReportCommentWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/report-comments',
      query: {
        filter: filter,
      },
    });
  }
}
