import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import ChatActionTypes from './types';

export type IAppChatState = {
  error?: string;
  newGroupMembers?: Array<any>;
  idToDelete?: string;
  activeChatRoomId?: string;
  onlineMembers?: string[];
};

export const INITIAL_USER_STATE = {
  error: '',
  newGroupMembers: [],
  idToDelete: '',
  activeChatRoomId: '',
  onlineMembers: [],
};

function chatReducer(state: IAppChatState = INITIAL_USER_STATE, { type, payload }) {
  switch (type) {
    case ChatActionTypes.UPDATE_NEW_GROUP_MEMBERS:
      return {
        ...state,
        ...payload,
      };
    case ChatActionTypes.ADD_ID_TO_DELETE:
      return {
        ...state,
        idToDelete: payload,
      };
    case ChatActionTypes.UPDATE_APP_CHAT_STATE:
      return {
        ...state,
        ...payload,
      };
    case ChatActionTypes.ACTIVE_CHAT_ROOM_ID:
      return {
        ...state,
        activeChatRoomId: payload,
      };
    default:
      return state;
  }
}

const persistConfig = {
  key: 'chat',
  storage,
  blacklist: ['error', 'isLoading', 'loading'],
};

export default persistReducer(persistConfig, chatReducer);
