/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewPrivilegeGroup } from '../models/NewPrivilegeGroup';
import type { PrivilegeGroup } from '../models/PrivilegeGroup';
import type { PrivilegeGroupPartialExcluding_id_ } from '../models/PrivilegeGroupPartialExcluding_id_';
import type { PrivilegeGroupWithRelations } from '../models/PrivilegeGroupWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivilegeGroupControllerService {
  /**
   * @returns void
   * @throws ApiError
   */
  public static privilegeGroupControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: PrivilegeGroupPartialExcluding_id_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/privilege-groups/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PrivilegeGroupWithRelations PrivilegeGroup model instance
   * @throws ApiError
   */
  public static privilegeGroupControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<PrivilegeGroupWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/privilege-groups/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static privilegeGroupControllerDeleteById({
    id,
  }: {
    id: string;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/privilege-groups/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns PrivilegeGroup PrivilegeGroup model instance
   * @throws ApiError
   */
  public static privilegeGroupControllerCreate({
    requestBody,
  }: {
    requestBody?: NewPrivilegeGroup;
  }): CancelablePromise<PrivilegeGroup> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/privilege-groups',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns PrivilegeGroupWithRelations Array of PrivilegeGroup model instances
   * @throws ApiError
   */
  public static privilegeGroupControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<PrivilegeGroupWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/privilege-groups',
      query: {
        filter: filter,
      },
    });
  }
}
