/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewSymptoms } from '../models/NewSymptoms';
import type { Symptoms } from '../models/Symptoms';
import type { SymptomsPartial } from '../models/SymptomsPartial';
import type { SymptomsWithRelations } from '../models/SymptomsWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SymptomsControllerService {
  /**
   * @returns any Symptoms model count
   * @throws ApiError
   */
  public static symptomsControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/symptoms/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any Symptoms  success
   * @throws ApiError
   */
  public static symptomsControllerGetUserSymptoms(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/symptoms/getUserSymptoms',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static symptomsControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Symptoms;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/symptoms/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static symptomsControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: SymptomsPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/symptoms/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SymptomsWithRelations Symptoms model instance
   * @throws ApiError
   */
  public static symptomsControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<SymptomsWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/symptoms/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static symptomsControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/symptoms/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Symptoms Symptoms model instance
   * @throws ApiError
   */
  public static symptomsControllerCreate({
    requestBody,
  }: {
    requestBody?: NewSymptoms;
  }): CancelablePromise<Symptoms> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/symptoms',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Symptoms PATCH success count
   * @throws ApiError
   */
  public static symptomsControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: SymptomsPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/symptoms',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns SymptomsWithRelations Array of Symptoms model instances
   * @throws ApiError
   */
  public static symptomsControllerFind({
    filter,
    page,
  }: {
    filter?: any;
    page?: number;
  }): CancelablePromise<Array<SymptomsWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/symptoms',
      query: {
        filter: filter,
        page: page,
      },
    });
  }
}
