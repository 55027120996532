/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Role } from '../models/Role';
import type { RolePartialExcluding_id_ } from '../models/RolePartialExcluding_id_';
import type { RoleWithRelations } from '../models/RoleWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class RoleControllerService {
  /**
   * @returns Role Role model instance
   * @throws ApiError
   */
  public static roleControllerAssignRolesToAdmin({
    requestBody,
  }: {
    requestBody?: {
      roles?: Array<string>;
      admin?: string;
    };
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/roles/assign',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static roleControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: {
      role?: RolePartialExcluding_id_;
      privileges?: Array<string>;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/roles/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns RoleWithRelations Role model instance
   * @throws ApiError
   */
  public static roleControllerFindById({
    id,
  }: {
    id: string;
  }): CancelablePromise<RoleWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/roles/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static roleControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/roles/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Role Role model instance
   * @throws ApiError
   */
  public static roleControllerCreate({
    requestBody,
  }: {
    requestBody?: {
      title?: string;
      description?: string;
      privileges?: Array<string>;
    };
  }): CancelablePromise<Role> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/roles',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns RoleWithRelations Array of Role model instances
   * @throws ApiError
   */
  public static roleControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<RoleWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/roles',
      query: {
        filter: filter,
      },
    });
  }
}
