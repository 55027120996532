/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NewVoucher } from '../models/NewVoucher';
import type { Voucher } from '../models/Voucher';
import type { VoucherPartial } from '../models/VoucherPartial';
import type { VoucherWithRelations } from '../models/VoucherWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VoucherControllerService {
  /**
   * @returns VoucherWithRelations Array of Voucher model instances
   * @throws ApiError
   */
  public static voucherControllerFindByCode({
    code,
    programId,
    planId,
    userId,
  }: {
    code?: string;
    programId?: string;
    planId?: string;
    userId?: string;
  }): CancelablePromise<VoucherWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/voucher/code',
      query: {
        code: code,
        programId: programId,
        planId: planId,
        userId: userId,
      },
    });
  }

  /**
   * @returns any Voucher model count
   * @throws ApiError
   */
  public static voucherControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/voucher/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static voucherControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: VoucherPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/voucher/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns VoucherWithRelations Voucher model instance
   * @throws ApiError
   */
  public static voucherControllerFindById({
    id,
  }: {
    id: string;
  }): CancelablePromise<VoucherWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/voucher/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static voucherControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/voucher/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Voucher Voucher model instance
   * @throws ApiError
   */
  public static voucherControllerCreate({
    requestBody,
  }: {
    requestBody?: NewVoucher;
  }): CancelablePromise<Voucher> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/voucher',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns VoucherWithRelations Array of Voucher model instances
   * @throws ApiError
   */
  public static voucherControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<VoucherWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/voucher',
      query: {
        filter: filter,
      },
    });
  }
}
