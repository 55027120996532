import Button from 'components/Buttons/Button';
import ModalComponent from 'components/Modal/Modal';
import { FC } from 'react';
import { getSecondsToHoursMinAndSecs } from 'utils/helpers';
import CountdownTimer from './CountDownTimer';
import { Countdown } from './CountDownWrapper';

interface IProps {
  isIdle: boolean;
  idleTimer: any;
  isOpen?: boolean;
  onClose?: () => void;
  logout: () => void;
  onSubmit?: (val?: any) => void;
}

const IdleTimerModal: FC<IProps> = ({ isOpen, onClose, isIdle, logout }) => {
  return (
    <ModalComponent title={''} size="sm" isOpen={isOpen} onClose={onClose}>
      <div className="px-10 pb-5 flex flex-col justify-center items-center gap-5">
        <Countdown callbackOnComplete={logout} duration={90}>
          {({ currentCountdown }) => {
            const { minWithZeroPrefix, secWithZeroPrefix } =
              getSecondsToHoursMinAndSecs(currentCountdown);

            return <CountdownTimer min={minWithZeroPrefix} sec={secWithZeroPrefix} />;
          }}
        </Countdown>
        <div className="flex flex-col items-center justify-center gap-2">
          <h3 className="font-semibold">Hey, are you there?</h3>
          <p className="text-center">
            You have been inactive for a while; You will be logged out shortly when the countdown
            ends.
          </p>
        </div>
        <Button
          backgroundColor="#2ecc71"
          borderRadius="100px"
          className="w-6/12 my-3 !normal-case"
          onClick={() => {
            onClose();
          }}
        >
          Stay active
        </Button>
      </div>
    </ModalComponent>
  );
};

export default IdleTimerModal;
