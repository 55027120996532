import RefreshIcon from '@mui/icons-material/Refresh';
import { styled } from '@mui/material/styles';
import { lighten } from 'polished';
import './primaryButton.css';

interface IProps {
  leftIcon?: React.ReactElement;
  isLoading?: boolean;
  children?: any;
  onClick?: any;
  className?: string;
  backgroundColor?: string;
  width?: string;
  height?: string;
  border?: string;
  styles?: any;
  borderRadius?: string;
  type?: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  color?: string;
}

const StyledButton = styled('button')<IProps>((props) => ({
  // ✅ typed-safe
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: props.width || 'auto',
  padding: '1rem 2rem',
  border: props.border || 'none',
  borderRadius: props.borderRadius || 5,
  // boxShadow: '0 12px 24px 0 rgba(0, 0, 0, 0.2)',
  color: props.color || 'white',
  fontWeight: '400',
  fontSize: '14px',
  textTransform: 'capitalize',
  overflow: 'hidden',
  minHeight: '40px',
  height: props.height || '40px',
  backgroundColor: props.disabled ? '#aaa' : props.backgroundColor || '#FD6E5E',
  '&:before': {
    content: "''",
    display: 'block',
    position: 'absolute',
    height: '100%',
    width: '100%',
    top: 0,
    right: 0,
    backgroundColor: props.backgroundColor || '#FD6E5E',
    zIndex: -1,
  },
  '&:after': props.isLoading
    ? {
        content: "''",
        display: 'block',
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        right: 0,
        backgroundColor: props.backgroundColor ? lighten('0.3', props.backgroundColor) : '#2ecc71',
        animation: 'loading 1.2s ease-in-out alternate both',
        zIndex: 0,
      }
    : {},
}));

const Button = ({
  leftIcon,
  isLoading,
  children,
  styles,
  onClick,
  className,
  width,
  type,
  disabled,
  backgroundColor = '#FD6E5E',
  border,
  borderRadius,
  color,
}: IProps) => {
  return (
    <>
      <StyledButton
        className={className}
        isLoading={isLoading}
        style={{
          width,
          ...styles,
        }}
        onClick={onClick}
        type={type}
        disabled={disabled}
        backgroundColor={backgroundColor}
        color={color}
        border={border}
        borderRadius={borderRadius}
      >
        {leftIcon && (
          <i
            style={{
              color,
            }}
          >
            {leftIcon}
          </i>
        )}
        <span className={isLoading ? 'opacity-0' : ''}>{children}</span>
        {isLoading ? (
          <RefreshIcon
            className={`${
              isLoading ? 'opacity-1' : 'opacity-0'
            } rotating absolute top-[23%] left-[43%] z-[2]`}
          />
        ) : null}
      </StyledButton>
    </>
  );
};

export default Button;
