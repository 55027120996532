import { AdminWithRelations } from 'generated';
import storage from 'localforage';
import { persistReducer } from 'redux-persist';
import userActionTypes from './types';

export type IAppUserState = {
  error?: string;
  isLoggedIn?: boolean;
  id?: string;
  accessToken?: string;
  email?: string;
  refreshToken?: string;
  isRefreshingToken?: boolean;
  currentUser?: AdminWithRelations;
};

export const INITIAL_USER_STATE = {
  error: '',
  isLoggedIn: false,
  id: '',
  accessToken: '',
  refreshToken: '',
  email: '',
};

function userReducer(state: IAppUserState = INITIAL_USER_STATE, { type, payload }) {
  switch (type) {
    case userActionTypes.UPDATE_APP_USER_STATE:
      return {
        ...state,
        ...payload,
      };
    case userActionTypes.LOGOUT:
      return INITIAL_USER_STATE;
    default:
      return state;
  }
}

const persistConfig = {
  key: 'user',
  storage,
  blacklist: ['error', 'isLoading', 'loading'],
};

export default persistReducer(persistConfig, userReducer);
