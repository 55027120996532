import notFoundImage from 'assets/images/404_image.png';
import logo from 'assets/images/mdoc_logo.png';

const NotFoundComponent = () => {
  return (
    <div className="w-full h-screen bg-offwhite">
      <div className="p-4 absolute">
        <img src={logo} />
      </div>
      <div className="h-full flex-1 flex flex-col items-center justify-center px-8">
        <div>
          <img src={notFoundImage} />
        </div>
        <div className="w-[90%] md:w-[60%] lg:w-[30%]">
          <h1 className="text-center text-red-500 font-bold">Ooops!</h1>
          <p className="mt-4 text-center text-[14px]">
            Oops, You seem lost, we can't find the page you're looking for! You might not have
            permission to view this page.
          </p>
          <p className="my-4 text-center">Go Back to </p>

          <div className="w-full flex justify-center">
            <button
              onClick={() => {
                window.location.href = '/';
              }}
            >
              Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundComponent;
