import { useCallback, useState } from 'react';

export interface IDisclosure {
  onClose: () => void;
  onOpen: () => void;
  isOpen: boolean;
  toggle: () => void;
}

const useDisclosure = (defaultOpen = false) => {
  const [isOpen, setIsOpen] = useState<boolean>(defaultOpen);
  const onOpen = useCallback(() => setIsOpen(true), []);
  const onClose = useCallback(() => setIsOpen(false), []);
  const toggle = useCallback(() => setIsOpen((prev) => !prev), []);

  return {
    onClose,
    onOpen,
    isOpen,
    toggle,
  };
};

export default useDisclosure;
