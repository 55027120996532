/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Admin } from '../models/Admin';
import type { AdminPartial } from '../models/AdminPartial';
import type { AdminWithRelations } from '../models/AdminWithRelations';
import type { NewAdmin } from '../models/NewAdmin';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AdminControllerService {
  /**
   * @returns any Admin activate success
   * @throws ApiError
   */
  public static adminControllerActivateUserById({ id }: { id: string }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/admins/activate/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static adminControllerAssignRoles({
    adminId,
    requestBody,
  }: {
    adminId: string;
    requestBody?: Array<string>;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins/assign-roles/{adminId}',
      path: {
        adminId: adminId,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Admin activate success
   * @throws ApiError
   */
  public static adminControllerChangePassword({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins/change-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static adminControllerDeactivateUserById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/admins/deactivate/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static adminControllerForgotPassword({
    requestBody,
  }: {
    /**
     * The input of forgot password function
     */
    requestBody: {
      email: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins/forgot-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Admin GET success
   * @throws ApiError
   */
  public static adminControllerGetUserByToken({
    token,
  }: {
    token?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admins/get-user-by-token',
      query: {
        token: token,
      },
    });
  }

  /**
   * @returns Admin Admin model instance
   * @throws ApiError
   */
  public static adminControllerCreateAdmin({
    requestBody,
  }: {
    requestBody?: NewAdmin;
  }): CancelablePromise<Admin> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins/invite',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Token
   * @throws ApiError
   */
  public static adminControllerLogin({
    requestBody,
  }: {
    /**
     * The input of login function
     */
    requestBody: {
      email: string;
      password: string;
      source?: 'mobile_app' | 'web';
      browserAgent?: string;
    };
  }): CancelablePromise<{
    token?: string;
  }> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins/login',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Logout
   * @throws ApiError
   */
  public static adminControllerLogout({
    requestBody,
  }: {
    requestBody?: {
      source?: 'mobile_app' | 'web';
      browserAgent?: string;
    };
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins/logout',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any token refreshed
   * @throws ApiError
   */
  public static adminControllerRefreshAdminToken({
    token,
  }: {
    token?: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admins/refresh-token',
      query: {
        token: token,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static adminControllerRegister({
    requestBody,
  }: {
    requestBody?: Admin;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins/register',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static adminControllerResetPassword({
    requestBody,
  }: {
    /**
     * The input of forgot password function
     */
    requestBody: {
      token: string;
      password: string;
    };
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins/reset-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static adminControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: AdminPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/admins/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static adminControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/admins/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Admin Admin model instance
   * @throws ApiError
   */
  public static adminControllerCreate({
    requestBody,
  }: {
    requestBody?: NewAdmin;
  }): CancelablePromise<Admin> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/admins',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Admin model instances
   * @throws ApiError
   */
  public static adminControllerFind({ filter }: { filter?: any }): CancelablePromise<{
    admins?: Array<AdminWithRelations>;
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/admins',
      query: {
        filter: filter,
      },
    });
  }
}
