import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks/useAppSelector';
interface LocationState {
  from?: { pathname: string };
}

const PublicRoute = ({ children }) => {
  const location = useLocation();
  const from = (location?.state as LocationState)?.from?.pathname || '/dashboard';
  const accessToken = useAppSelector((state) => state.user.accessToken);
  const isLoggedIn = useAppSelector((state) => state.user.isLoggedIn);
  const auth = !!(isLoggedIn && accessToken);

  return auth ? <Navigate to={from} replace /> : children;
};

export default PublicRoute;
