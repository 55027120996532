/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Career } from '../models/Career';
import type { CareerExcluding_id_ } from '../models/CareerExcluding_id_';
import type { CareerPartial } from '../models/CareerPartial';
import type { CareerWithRelations } from '../models/CareerWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class CareerControllerService {
  /**
   * @returns any Career model count
   * @throws ApiError
   */
  public static careerControllerStatistics(): CancelablePromise<{
    allJobs?: number;
    ongoingJobs?: number;
    draftJobs?: number;
    closedJobs?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/careers/statistics',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static careerControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: CareerPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/careers/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns CareerWithRelations Career model instance
   * @throws ApiError
   */
  public static careerControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<CareerWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/careers/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns Career Notes model instance
   * @throws ApiError
   */
  public static careerControllerCreate({
    requestBody,
  }: {
    requestBody?: CareerExcluding_id_;
  }): CancelablePromise<Career> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/careers',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Array of Career model instances
   * @throws ApiError
   */
  public static careerControllerFind({
    filter,
    page,
    search,
  }: {
    filter?: any;
    page?: number;
    search?: string;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<CareerWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/careers',
      query: {
        filter: filter,
        page: page,
        search: search,
      },
    });
  }
}
