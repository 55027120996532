/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Appointment } from '../models/Appointment';
import type { AppointmentPartial } from '../models/AppointmentPartial';
import type { AppointmentWithRelations } from '../models/AppointmentWithRelations';
import type { NewAppointment } from '../models/NewAppointment';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AppointmentControllerService {
  /**
   * @returns any Appointment model count
   * @throws ApiError
   */
  public static appointmentControllerCount({ where }: { where?: any }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/appointments/count',
      query: {
        where: where,
      },
    });
  }

  /**
   * @returns any Appointment  success
   * @throws ApiError
   */
  public static appointmentControllerGetAppointmentList({
    requestBody,
  }: {
    requestBody?: any;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/appointments/getAppointmentList',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Appointment  success
   * @throws ApiError
   */
  public static appointmentControllerGetAppointmentsByCoachId({
    coachId,
  }: {
    coachId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/appointments/getAppointmentsByCoachId/{coachId}',
      path: {
        coachId: coachId,
      },
    });
  }

  /**
   * @returns any Appointment  success
   * @throws ApiError
   */
  public static appointmentControllerGetCurrentAppointmentList(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/appointments/getCurrentAppointmentList',
    });
  }

  /**
   * @returns any Appointment  success
   * @throws ApiError
   */
  public static appointmentControllerGetPastAppointmentList({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/appointments/getPastAppointmentList/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns any Appointment  success
   * @throws ApiError
   */
  public static appointmentControllerGetUpcomingAppointmentList({
    userId,
  }: {
    userId: string;
  }): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/appointments/getUpcomingAppointmentList/{userId}',
      path: {
        userId: userId,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static appointmentControllerReplaceById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: Appointment;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/appointments/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static appointmentControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: AppointmentPartial;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/appointments/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AppointmentWithRelations Appointment model instance
   * @throws ApiError
   */
  public static appointmentControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<AppointmentWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/appointments/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static appointmentControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/appointments/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns Appointment Appointment model instance
   * @throws ApiError
   */
  public static appointmentControllerCreate({
    requestBody,
  }: {
    requestBody?: NewAppointment;
  }): CancelablePromise<Appointment> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/appointments',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns any Appointment PATCH success count
   * @throws ApiError
   */
  public static appointmentControllerUpdateAll({
    where,
    requestBody,
  }: {
    where?: any;
    requestBody?: AppointmentPartial;
  }): CancelablePromise<{
    count?: number;
  }> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/appointments',
      query: {
        where: where,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns AppointmentWithRelations Array of Appointment model instances
   * @throws ApiError
   */
  public static appointmentControllerFind({
    filter,
  }: {
    filter?: any;
  }): CancelablePromise<Array<AppointmentWithRelations>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/appointments',
      query: {
        filter: filter,
      },
    });
  }
}
