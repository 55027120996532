import { apiWrapper } from 'api';
import { ChatRoom, ChatRoomControllerService, ChatRoomWithRelations } from 'generated';
import { Dispatch, SetStateAction, createContext, useContext, useState } from 'react';
import { useAppSelector } from 'redux/hooks/useAppSelector';
import { handleError } from 'utils/handleError';

interface IChatSocketContext {
  chatSocket?: any;
  setActiveMemberChatRoom: Dispatch<SetStateAction<ChatRoom | undefined>>;
  activeMemberChatRoom: ChatRoom | undefined;
  setActiveChatRoom: Dispatch<SetStateAction<ChatRoom | undefined>>;
  activeChatRoom: ChatRoom | undefined;
  getChatRoom: (val: { id: string; callback?: () => void }) => Promise<ChatRoomWithRelations>;
}

const ChatSocketContext = createContext<IChatSocketContext>(null);

export function useChatSocketContext() {
  const context = useContext(ChatSocketContext);
  if (!context) {
    throw new Error('useChatSocketContext must be used within a ChatSocketProvider');
  }
  return context;
}

const getChatRoom = async ({ id, callback }: { id: string; callback?: () => void }) => {
  try {
    const data = await apiWrapper(() =>
      ChatRoomControllerService.chatRoomControllerGetSingleChatroom({ id, type: 'coach' }),
    );
    callback?.();
    return data;
  } catch (error) {
    handleError(error);
  }
};

const ChatSocketProvider = ({ children }) => {
  const admin = useAppSelector((state) => state.user?.currentUser);
  const [activeChatRoom, setActiveChatRoom] = useState<ChatRoom | undefined>(undefined);
  const [activeMemberChatRoom, setActiveMemberChatRoom] = useState<ChatRoom | undefined>(undefined);

  return (
    <ChatSocketContext.Provider
      value={{
        activeChatRoom,
        setActiveChatRoom,
        getChatRoom,
        activeMemberChatRoom,
        setActiveMemberChatRoom,
      }}
    >
      {children}
    </ChatSocketContext.Provider>
  );
};

export default ChatSocketProvider;
