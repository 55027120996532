/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DashboardStatisticsControllerService {
  /**
   * @returns any Dashboard Statistics
   * @throws ApiError
   */
  public static dashboardStatisticsControllerGetDashboardStatistics(): CancelablePromise<{
    totalCoaches?: number;
    totalMaleCoaches?: number;
    totalFemaleCoaches?: number;
    totalMembers?: number;
    totalMaleMembers?: number;
    totalFemaleMembers?: number;
    totalSubscriptions?: number;
    totalActiveSubscriptions?: number;
    totalExpiredSubscriptions?: number;
    totalPrograms?: number;
    totalPendingPrograms?: number;
    totalApprovedPrograms?: number;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/dashboard-statistics',
    });
  }
}
