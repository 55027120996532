/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BlogPost } from '../models/BlogPost';
import type { BlogPostPartialExcluding_id_creatorId_created_at_ } from '../models/BlogPostPartialExcluding_id_creatorId_created_at_';
import type { BlogPostWithRelations } from '../models/BlogPostWithRelations';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BlogPostControllerService {
  /**
   * @returns BlogPostWithRelations BlogPost model instance
   * @throws ApiError
   */
  public static blogPostControllerFindBySlug({
    slug,
  }: {
    slug: string;
  }): CancelablePromise<BlogPostWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blog-post-slug/{slug}',
      path: {
        slug: slug,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static blogPostControllerBulkUpdate({
    requestBody,
  }: {
    requestBody?: Array<{
      id: string;
      order: number;
    }>;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/blog-posts/bulk',
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static blogPostControllerUpdateById({
    id,
    requestBody,
  }: {
    id: string;
    requestBody?: BlogPostPartialExcluding_id_creatorId_created_at_;
  }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/blog-posts/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }

  /**
   * @returns BlogPostWithRelations BlogPost model instance
   * @throws ApiError
   */
  public static blogPostControllerFindById({
    id,
    filter,
  }: {
    id: string;
    filter?: any;
  }): CancelablePromise<BlogPostWithRelations> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blog-posts/{id}',
      path: {
        id: id,
      },
      query: {
        filter: filter,
      },
    });
  }

  /**
   * @returns void
   * @throws ApiError
   */
  public static blogPostControllerDeleteById({ id }: { id: string }): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/blog-posts/{id}',
      path: {
        id: id,
      },
    });
  }

  /**
   * @returns BlogPost BlogPost model instance
   * @throws ApiError
   */
  public static blogPostControllerCreate({
    formData,
  }: {
    formData?: {
      title?: string;
      status?: number;
      description?: string;
      content?: string;
      order?: number;
      postedDate?: string;
    };
  }): CancelablePromise<BlogPost> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/blog-posts',
      formData: formData,
      mediaType: 'multipart/form-data',
    });
  }

  /**
   * @returns any Array of BlogPost model instances
   * @throws ApiError
   */
  public static blogPostControllerFind({
    order,
    search,
    startDate,
    endDate,
    filter,
    page,
  }: {
    order?: string;
    search?: string;
    startDate?: string;
    endDate?: string;
    filter?: any;
    page?: number;
  }): CancelablePromise<{
    currentPage?: number;
    totalPages?: number;
    count?: number;
    status?: string;
    data?: Array<BlogPostWithRelations>;
  }> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/blog-posts',
      query: {
        order: order,
        search: search,
        startDate: startDate,
        endDate: endDate,
        filter: filter,
        page: page,
      },
    });
  }
}
